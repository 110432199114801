import LoadingOutlined from '@ant-design/icons-vue/LoadingOutlined';
import CloseCircleFilled from '@ant-design/icons-vue/CloseCircleFilled';
import CheckCircleFilled from '@ant-design/icons-vue/CheckCircleFilled';
import ExclamationCircleFilled from '@ant-design/icons-vue/ExclamationCircleFilled';
import Col from '../grid/Col';
import { useProvideForm, useInjectForm, useProvideFormItemPrefix } from './context';
import ErrorList from './ErrorList';
import classNames from '../_util/classNames';
import { computed, defineComponent, onUnmounted } from 'vue';
const iconMap = {
    success: CheckCircleFilled,
    warning: ExclamationCircleFilled,
    error: CloseCircleFilled,
    validating: LoadingOutlined,
};
const FormItemInput = defineComponent({
    slots: ['help', 'extra', 'errors'],
    inheritAttrs: false,
    props: [
        'prefixCls',
        'errors',
        'hasFeedback',
        'validateStatus',
        'onDomErrorVisibleChange',
        'wrapperCol',
        'help',
        'extra',
        'status',
    ],
    setup(props, { slots }) {
        const formContext = useInjectForm();
        const { wrapperCol: contextWrapperCol } = formContext;
        // Pass to sub FormItem should not with col info
        const subFormContext = Object.assign({}, formContext);
        delete subFormContext.labelCol;
        delete subFormContext.wrapperCol;
        useProvideForm(subFormContext);
        useProvideFormItemPrefix({
            prefixCls: computed(() => props.prefixCls),
            status: computed(() => props.status),
        });
        onUnmounted(() => {
            props.onDomErrorVisibleChange(false);
        });
        return () => {
            var _a, _b, _c, _d;
            const { prefixCls, wrapperCol, help = (_a = slots.help) === null || _a === void 0 ? void 0 : _a.call(slots), errors = (_b = slots.errors) === null || _b === void 0 ? void 0 : _b.call(slots), onDomErrorVisibleChange, hasFeedback, validateStatus, extra = (_c = slots.extra) === null || _c === void 0 ? void 0 : _c.call(slots), } = props;
            const baseClassName = `${prefixCls}-item`;
            const mergedWrapperCol = wrapperCol || (contextWrapperCol === null || contextWrapperCol === void 0 ? void 0 : contextWrapperCol.value) || {};
            const className = classNames(`${baseClassName}-control`, mergedWrapperCol.class);
            // Should provides additional icon if `hasFeedback`
            const IconNode = validateStatus && iconMap[validateStatus];
            const icon = hasFeedback && IconNode ? (<span class={`${baseClassName}-children-icon`}>
            <IconNode />
          </span>) : null;
            const inputDom = (<div class={`${baseClassName}-control-input`}>
          <div class={`${baseClassName}-control-input-content`}>{(_d = slots.default) === null || _d === void 0 ? void 0 : _d.call(slots)}</div>
          {icon}
        </div>);
            const errorListDom = (<ErrorList errors={errors} help={help} onDomErrorVisibleChange={onDomErrorVisibleChange}/>);
            // If extra = 0, && will goes wrong
            // 0&&error -> 0
            const extraDom = extra ? <div class={`${baseClassName}-extra`}>{extra}</div> : null;
            return (<Col {...mergedWrapperCol} class={className}>
          {inputDom}
          {errorListDom}
          {extraDom}
        </Col>);
        };
    },
});
export default FormItemInput;
