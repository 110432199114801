import Tabs from './tabs';
import TabPane from '../vc-tabs/src/TabPane';
import TabContent from '../vc-tabs/src/TabContent';
Tabs.TabPane = Object.assign(Object.assign({}, TabPane), { name: 'ATabPane', __ANT_TAB_PANE: true });
Tabs.TabContent = Object.assign(Object.assign({}, TabContent), { name: 'ATabContent' });
/* istanbul ignore next */
Tabs.install = function (app) {
    app.component(Tabs.name, Tabs);
    app.component(Tabs.TabPane.name, Tabs.TabPane);
    app.component(Tabs.TabContent.name, Tabs.TabContent);
    return app;
};
export default Tabs;
export { TabPane, TabContent };
