import { ref, provide, defineComponent, inject, reactive } from 'vue';
import Preview from './Preview';
const previewGroupContext = Symbol('previewGroupContext');
export const context = {
    provide: (val) => {
        provide(previewGroupContext, val);
    },
    inject: () => {
        return inject(previewGroupContext, {
            isPreviewGroup: ref(false),
            previewUrls: reactive({}),
            setPreviewUrls: () => { },
            current: ref(null),
            setCurrent: () => { },
            setShowPreview: () => { },
            setMousePosition: () => { },
            registerImage: null,
        });
    },
};
const Group = defineComponent({
    name: 'PreviewGroup',
    inheritAttrs: false,
    props: { previewPrefixCls: String },
    setup(props, { slots }) {
        const previewUrls = reactive({});
        const current = ref();
        const isShowPreview = ref(false);
        const mousePosition = ref(null);
        const setPreviewUrls = (val) => {
            Object.assign(previewUrls, val);
        };
        const setCurrent = (val) => {
            current.value = val;
        };
        const setMousePosition = (val) => {
            mousePosition.value = val;
        };
        const setShowPreview = (val) => {
            isShowPreview.value = val;
        };
        const registerImage = (id, url) => {
            previewUrls[id] = url;
            return () => {
                delete previewUrls[id];
            };
        };
        const onPreviewClose = (e) => {
            e === null || e === void 0 ? void 0 : e.stopPropagation();
            isShowPreview.value = false;
            mousePosition.value = null;
        };
        context.provide({
            isPreviewGroup: ref(true),
            previewUrls,
            setPreviewUrls,
            current,
            setCurrent,
            setShowPreview,
            setMousePosition,
            registerImage,
        });
        return () => {
            return (<>
          {slots.default && slots.default()}
          <Preview ria-hidden={!isShowPreview.value} visible={isShowPreview.value} prefixCls={props.previewPrefixCls} onClose={onPreviewClose} mousePosition={mousePosition.value} src={previewUrls[current.value]}/>
        </>);
        };
    },
});
export default Group;
