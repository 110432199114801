var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { defineComponent, inject, nextTick } from 'vue';
import classNames from '../_util/classNames';
import omit from 'omit.js';
import PropTypes from '../_util/vue-types';
import VcMentions from '../vc-mentions';
import { mentionsProps as baseMentionsProps } from '../vc-mentions/src/mentionsProps';
import Spin from '../spin';
import BaseMixin from '../_util/BaseMixin';
import { defaultConfigProvider } from '../config-provider';
import { getOptionProps, getComponent, getSlot } from '../_util/props-util';
const { Option } = VcMentions;
function loadingFilterOption() {
    return true;
}
function getMentions(value = '', config) {
    const { prefix = '@', split = ' ' } = config || {};
    const prefixList = Array.isArray(prefix) ? prefix : [prefix];
    return value
        .split(split)
        .map((str = '') => {
        let hitPrefix = null;
        prefixList.some(prefixStr => {
            const startStr = str.slice(0, prefixStr.length);
            if (startStr === prefixStr) {
                hitPrefix = prefixStr;
                return true;
            }
            return false;
        });
        if (hitPrefix !== null) {
            return {
                prefix: hitPrefix,
                value: str.slice(hitPrefix.length),
            };
        }
        return null;
    })
        .filter(entity => !!entity && !!entity.value);
}
const mentionsProps = Object.assign(Object.assign({}, baseMentionsProps), { loading: PropTypes.looseBool, onFocus: {
        type: Function,
    }, onBlur: {
        type: Function,
    }, onSelect: {
        type: Function,
    }, onChange: {
        type: Function,
    } });
const Mentions = defineComponent({
    name: 'AMentions',
    mixins: [BaseMixin],
    inheritAttrs: false,
    Option: Object.assign(Object.assign({}, Option), { name: 'AMentionsOption' }),
    getMentions,
    props: mentionsProps,
    emits: ['update:value', 'change', 'focus', 'blur', 'select'],
    setup() {
        return {
            configProvider: inject('configProvider', defaultConfigProvider),
        };
    },
    data() {
        return {
            focused: false,
        };
    },
    mounted() {
        nextTick(() => {
            if (process.env.NODE_ENV === 'test') {
                if (this.autofocus) {
                    this.focus();
                }
            }
        });
    },
    methods: {
        handleFocus(e) {
            this.$emit('focus', e);
            this.setState({
                focused: true,
            });
        },
        handleBlur(e) {
            this.$emit('blur', e);
            this.setState({
                focused: false,
            });
        },
        handleSelect(...args) {
            this.$emit('select', ...args);
            this.setState({
                focused: true,
            });
        },
        handleChange(val) {
            this.$emit('update:value', val);
            this.$emit('change', val);
        },
        getNotFoundContent(renderEmpty) {
            const notFoundContent = getComponent(this, 'notFoundContent');
            if (notFoundContent !== undefined) {
                return notFoundContent;
            }
            return renderEmpty('Select');
        },
        getOptions() {
            const { loading } = this.$props;
            const children = getSlot(this);
            if (loading) {
                return (<Option value="ANTD_SEARCHING" disabled>
            <Spin size="small"/>
          </Option>);
            }
            return children;
        },
        getFilterOption() {
            const { filterOption, loading } = this.$props;
            if (loading) {
                return loadingFilterOption;
            }
            return filterOption;
        },
        focus() {
            this.$refs.vcMentions.focus();
        },
        blur() {
            this.$refs.vcMentions.blur();
        },
    },
    render() {
        const { focused } = this.$data;
        const { getPrefixCls, renderEmpty } = this.configProvider;
        const _a = getOptionProps(this), { prefixCls: customizePrefixCls, disabled, getPopupContainer } = _a, restProps = __rest(_a, ["prefixCls", "disabled", "getPopupContainer"]);
        const _b = this.$attrs, { class: className } = _b, otherAttrs = __rest(_b, ["class"]);
        const prefixCls = getPrefixCls('mentions', customizePrefixCls);
        const otherProps = omit(restProps, ['loading', 'onUpdate:value']);
        const mergedClassName = classNames(className, {
            [`${prefixCls}-disabled`]: disabled,
            [`${prefixCls}-focused`]: focused,
        });
        const mentionsProps = Object.assign(Object.assign(Object.assign(Object.assign({ prefixCls, notFoundContent: this.getNotFoundContent(renderEmpty) }, otherProps), { disabled, filterOption: this.getFilterOption(), getPopupContainer, children: this.getOptions(), class: mergedClassName, rows: 1 }), otherAttrs), { onChange: this.handleChange, onSelect: this.handleSelect, onFocus: this.handleFocus, onBlur: this.handleBlur, ref: 'vcMentions' });
        return <VcMentions {...mentionsProps}/>;
    },
});
/* istanbul ignore next */
Mentions.install = function (app) {
    app.component(Mentions.name, Mentions);
    app.component(Mentions.Option.name, Mentions.Option);
    return app;
};
export const MentionsOption = Mentions.Option;
export default Mentions;
