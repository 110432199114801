var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { defineComponent, inject, nextTick, onMounted, ref } from 'vue';
import PropTypes from '../_util/vue-types';
import { getOptionProps } from '../_util/props-util';
import classNames from '../_util/classNames';
import UpOutlined from '@ant-design/icons-vue/UpOutlined';
import DownOutlined from '@ant-design/icons-vue/DownOutlined';
import VcInputNumber from '../vc-input-number/src';
import { defaultConfigProvider } from '../config-provider';
import { tuple, withInstall } from '../_util/type';
const inputNumberProps = {
    prefixCls: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).def(1),
    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tabindex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    disabled: PropTypes.looseBool,
    size: PropTypes.oneOf(tuple('large', 'small', 'default')),
    formatter: PropTypes.func,
    parser: PropTypes.func,
    decimalSeparator: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    precision: PropTypes.number,
    autofocus: PropTypes.looseBool,
    onPressEnter: {
        type: Function,
    },
    onChange: Function,
};
const InputNumber = defineComponent({
    name: 'AInputNumber',
    inheritAttrs: false,
    props: inputNumberProps,
    setup(props) {
        const inputNumberRef = ref(null);
        const focus = () => {
            inputNumberRef.value.focus();
        };
        const blur = () => {
            inputNumberRef.value.blur();
        };
        onMounted(() => {
            nextTick(() => {
                if (process.env.NODE_ENV === 'test') {
                    if (props.autofocus && !props.disabled) {
                        focus();
                    }
                }
            });
        });
        return {
            configProvider: inject('configProvider', defaultConfigProvider),
            inputNumberRef,
            focus,
            blur,
        };
    },
    render() {
        const _a = Object.assign(Object.assign({}, getOptionProps(this)), this.$attrs), { prefixCls: customizePrefixCls, size, class: className } = _a, others = __rest(_a, ["prefixCls", "size", "class"]);
        const { getPrefixCls } = this.configProvider;
        const prefixCls = getPrefixCls('input-number', customizePrefixCls);
        const inputNumberClass = classNames({
            [`${prefixCls}-lg`]: size === 'large',
            [`${prefixCls}-sm`]: size === 'small',
        }, className);
        const upIcon = <UpOutlined class={`${prefixCls}-handler-up-inner`}/>;
        const downIcon = <DownOutlined class={`${prefixCls}-handler-down-inner`}/>;
        const vcInputNumberProps = Object.assign(Object.assign({ prefixCls, upHandler: upIcon, downHandler: downIcon }, others), { class: inputNumberClass });
        return <VcInputNumber {...vcInputNumberProps} ref="inputNumberRef"/>;
    },
});
export default withInstall(InputNumber);
