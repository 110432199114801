import { onMounted, onUnmounted, ref } from 'vue';
import ResponsiveObserve from '../../_util/responsiveObserve';
function useBreakpoint() {
    const screens = ref({});
    let token = null;
    onMounted(() => {
        token = ResponsiveObserve.subscribe(supportScreens => {
            screens.value = supportScreens;
        });
    });
    onUnmounted(() => {
        ResponsiveObserve.unsubscribe(token);
    });
    return screens;
}
export default useBreakpoint;
