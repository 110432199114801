import { defineComponent, onBeforeMount, ref, computed, onMounted, nextTick, watch } from 'vue';
import LoadingOutlined from '@ant-design/icons-vue/LoadingOutlined';
import PropTypes from '../_util/vue-types';
import KeyCode from '../_util/KeyCode';
import Wave from '../_util/wave';
import warning from '../_util/warning';
import { tuple, withInstall } from '../_util/type';
import { getPropsSlot } from '../_util/props-util';
import Omit from 'omit.js';
import useConfigInject from '../_util/hooks/useConfigInject';
export const SwitchSizes = tuple('small', 'default');
const switchProps = {
    prefixCls: PropTypes.string,
    size: PropTypes.oneOf(SwitchSizes),
    disabled: PropTypes.looseBool,
    checkedChildren: PropTypes.VNodeChild,
    unCheckedChildren: PropTypes.VNodeChild,
    tabindex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    autofocus: PropTypes.looseBool,
    loading: PropTypes.looseBool,
    checked: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.looseBool]),
    checkedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.looseBool]).def(true),
    unCheckedValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.looseBool,
    ]).def(false),
    onChange: {
        type: Function,
    },
    onClick: {
        type: Function,
    },
    onKeydown: {
        type: Function,
    },
    onMouseup: {
        type: Function,
    },
    'onUpdate:checked': {
        type: Function,
    },
};
const Switch = defineComponent({
    name: 'ASwitch',
    __ANT_SWITCH: true,
    inheritAttrs: false,
    props: switchProps,
    slots: ['checkedChildren', 'unCheckedChildren'],
    emits: ['update:checked', 'mouseup', 'change', 'click', 'keydown'],
    setup(props, { attrs, slots, expose, emit }) {
        onBeforeMount(() => {
            warning(!('defaultChecked' in attrs), 'Switch', `'defaultChecked' is deprecated, please use 'v-model:checked'`);
            warning(!('value' in attrs), 'Switch', '`value` is not validate prop, do you mean `checked`?');
        });
        const checked = ref(props.checked !== undefined ? props.checked : attrs.defaultChecked);
        const checkedStatus = computed(() => checked.value === props.checkedValue);
        watch(() => props.checked, () => {
            checked.value = props.checked;
        });
        const { prefixCls } = useConfigInject('switch', props);
        const refSwitchNode = ref();
        const focus = () => {
            var _a;
            (_a = refSwitchNode.value) === null || _a === void 0 ? void 0 : _a.focus();
        };
        const blur = () => {
            var _a;
            (_a = refSwitchNode.value) === null || _a === void 0 ? void 0 : _a.blur();
        };
        expose({ focus, blur });
        onMounted(() => {
            nextTick(() => {
                if (props.autofocus && !props.disabled) {
                    refSwitchNode.value.focus();
                }
            });
        });
        const setChecked = (check, e) => {
            if (props.disabled) {
                return;
            }
            emit('update:checked', check);
            emit('change', check, e);
        };
        const handleClick = (e) => {
            focus();
            const newChecked = checkedStatus.value ? props.unCheckedValue : props.checkedValue;
            setChecked(newChecked, e);
            emit('click', newChecked, e);
        };
        const handleKeyDown = (e) => {
            if (e.keyCode === KeyCode.LEFT) {
                setChecked(props.unCheckedValue, e);
            }
            else if (e.keyCode === KeyCode.RIGHT) {
                setChecked(props.checkedValue, e);
            }
            emit('keydown', e);
        };
        const handleMouseUp = (e) => {
            var _a;
            (_a = refSwitchNode.value) === null || _a === void 0 ? void 0 : _a.blur();
            emit('mouseup', e);
        };
        const classNames = computed(() => ({
            [`${prefixCls.value}-small`]: props.size === 'small',
            [`${prefixCls.value}-loading`]: props.loading,
            [`${prefixCls.value}-checked`]: checkedStatus.value,
            [`${prefixCls.value}-disabled`]: props.disabled,
            [prefixCls.value]: true,
        }));
        return () => (<Wave insertExtraNode>
        <button {...Omit(props, [
            'prefixCls',
            'checkedChildren',
            'unCheckedChildren',
            'checked',
            'autofocus',
            'defaultChecked',
            'checkedValue',
            'unCheckedValue',
        ])} {...attrs} onKeydown={handleKeyDown} onClick={handleClick} onMouseup={handleMouseUp} type="button" role="switch" aria-checked={checked.value} disabled={props.disabled || props.loading} class={[attrs.class, classNames.value]} ref={refSwitchNode}>
          {props.loading ? <LoadingOutlined class={`${prefixCls.value}-loading-icon`}/> : null}
          <span class={`${prefixCls.value}-inner`}>
            {checkedStatus.value
                ? getPropsSlot(slots, props, 'checkedChildren')
                : getPropsSlot(slots, props, 'unCheckedChildren')}
          </span>
        </button>
      </Wave>);
    },
});
export default withInstall(Switch);
