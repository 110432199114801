import { computed, inject } from 'vue';
import { defaultConfigProvider } from '../../config-provider';
export default (name, props) => {
    const configProvider = inject('configProvider', defaultConfigProvider);
    const prefixCls = computed(() => configProvider.getPrefixCls(name, props.prefixCls));
    const direction = computed(() => configProvider.direction);
    const autoInsertSpaceInButton = computed(() => configProvider.autoInsertSpaceInButton);
    const renderEmpty = computed(() => configProvider.renderEmpty);
    const space = computed(() => configProvider.space);
    const pageHeader = computed(() => configProvider.pageHeader);
    const form = computed(() => configProvider.form);
    const size = computed(() => props.size || configProvider.componentSize);
    const getTargetContainer = computed(() => props.getTargetContainer);
    return {
        configProvider,
        prefixCls,
        direction,
        size,
        getTargetContainer,
        space,
        pageHeader,
        form,
        autoInsertSpaceInButton,
        renderEmpty,
    };
};
