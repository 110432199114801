import { reactive, provide, defineComponent, watch } from 'vue';
import PropTypes from '../_util/vue-types';
import defaultRenderEmpty from './renderEmpty';
import LocaleProvider, { ANT_MARK } from '../locale-provider';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import { withInstall } from '../_util/type';
export const configConsumerProps = [
    'getTargetContainer',
    'getPopupContainer',
    'rootPrefixCls',
    'getPrefixCls',
    'renderEmpty',
    'csp',
    'autoInsertSpaceInButton',
    'locale',
    'pageHeader',
];
export const configProviderProps = {
    getTargetContainer: {
        type: Function,
    },
    getPopupContainer: {
        type: Function,
    },
    prefixCls: String,
    getPrefixCls: {
        type: Function,
    },
    renderEmpty: {
        type: Function,
    },
    transformCellText: {
        type: Function,
    },
    csp: {
        type: Object,
    },
    autoInsertSpaceInButton: PropTypes.looseBool,
    locale: {
        type: Object,
    },
    pageHeader: {
        type: Object,
    },
    componentSize: {
        type: String,
    },
    direction: {
        type: String,
    },
    space: {
        type: Object,
    },
    virtual: PropTypes.looseBool,
    dropdownMatchSelectWidth: PropTypes.looseBool,
    form: {
        type: Object,
    },
};
const ConfigProvider = defineComponent({
    name: 'AConfigProvider',
    props: configProviderProps,
    setup(props, { slots }) {
        const getPrefixCls = (suffixCls, customizePrefixCls) => {
            const { prefixCls = 'ant' } = props;
            if (customizePrefixCls)
                return customizePrefixCls;
            return suffixCls ? `${prefixCls}-${suffixCls}` : prefixCls;
        };
        const renderEmptyComponent = (name) => {
            const renderEmpty = (props.renderEmpty ||
                slots.renderEmpty ||
                defaultRenderEmpty);
            return renderEmpty(name);
        };
        const getPrefixClsWrapper = (suffixCls, customizePrefixCls) => {
            const { prefixCls } = props;
            if (customizePrefixCls)
                return customizePrefixCls;
            const mergedPrefixCls = prefixCls || getPrefixCls('');
            return suffixCls ? `${mergedPrefixCls}-${suffixCls}` : mergedPrefixCls;
        };
        const configProvider = reactive(Object.assign(Object.assign({}, props), { getPrefixCls: getPrefixClsWrapper, renderEmpty: renderEmptyComponent }));
        Object.keys(props).forEach(key => {
            watch(() => props[key], () => {
                configProvider[key] = props[key];
            });
        });
        provide('configProvider', configProvider);
        const renderProvider = (legacyLocale) => {
            var _a;
            return (<LocaleProvider locale={props.locale || legacyLocale} ANT_MARK__={ANT_MARK}>
          {(_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)}
        </LocaleProvider>);
        };
        return () => (<LocaleReceiver children={(_, __, legacyLocale) => renderProvider(legacyLocale)}/>);
    },
});
export const defaultConfigProvider = reactive({
    getPrefixCls: (suffixCls, customizePrefixCls) => {
        if (customizePrefixCls)
            return customizePrefixCls;
        return suffixCls ? `ant-${suffixCls}` : 'ant';
    },
    renderEmpty: defaultRenderEmpty,
    direction: 'ltr',
});
export default withInstall(ConfigProvider);
