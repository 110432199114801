import CalendarOutlined from '@ant-design/icons-vue/CalendarOutlined';
import { isValidElement } from '../_util/props-util';
import { cloneElement } from '../_util/vnode';
import classNames from '../_util/classNames';
const InputIcon = (_, { attrs }) => {
    var _a, _b;
    const { suffixIcon, prefixCls } = attrs;
    return ((suffixIcon && isValidElement(suffixIcon) ? (cloneElement(suffixIcon, {
        class: classNames({
            [(_a = suffixIcon.props) === null || _a === void 0 ? void 0 : _a.class]: (_b = suffixIcon.props) === null || _b === void 0 ? void 0 : _b.class,
            [`${prefixCls}-picker-icon`]: true,
        }),
    })) : (<span class={`${prefixCls}-picker-icon`}>{suffixIcon}</span>)) || <CalendarOutlined class={`${prefixCls}-picker-icon`}/>);
};
InputIcon.inheritAttrs = false;
export default InputIcon;
