import { computed, inject, provide } from 'vue';
import { defaultConfigProvider } from '../../config-provider';
const sizeProvider = Symbol('SizeProvider');
const useProvideSize = (props) => {
    const configProvider = inject('configProvider', defaultConfigProvider);
    const size = computed(() => props.size || configProvider.componentSize);
    provide(sizeProvider, size);
    return size;
};
const useInjectSize = (props) => {
    const size = props
        ? computed(() => props.size)
        : inject(sizeProvider, computed(() => 'default'));
    return size;
};
export { useInjectSize, sizeProvider, useProvideSize };
export default useProvideSize;
