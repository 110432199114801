import { inject } from 'vue';
import Empty from '../empty';
import { defaultConfigProvider } from '.';
const RenderEmpty = (props) => {
    const configProvider = inject('configProvider', defaultConfigProvider);
    const renderHtml = (componentName) => {
        const { getPrefixCls } = configProvider;
        const prefix = getPrefixCls('empty');
        switch (componentName) {
            case 'Table':
            case 'List':
                return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>;
            case 'Select':
            case 'TreeSelect':
            case 'Cascader':
            case 'Transfer':
            case 'Mentions':
                return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} class={`${prefix}-small`}/>;
            default:
                return <Empty />;
        }
    };
    return renderHtml(props.componentName);
};
function renderEmpty(componentName) {
    return <RenderEmpty componentName={componentName}/>;
}
export default renderEmpty;
