import raf from '../../../_util/raf';
import { onMounted, reactive, ref } from 'vue';
export default function useFrameSetState(initial) {
    const frame = ref(null);
    const state = reactive(Object.assign({}, initial));
    const queue = ref([]);
    const setFrameState = (newState) => {
        if (frame.value === null) {
            queue.value = [];
            frame.value = raf(() => {
                let memoState;
                queue.value.forEach((queueState) => {
                    memoState = Object.assign(Object.assign({}, memoState), queueState);
                });
                Object.assign(state, memoState);
                frame.value = null;
            });
        }
        queue.value.push(newState);
    };
    onMounted(() => {
        frame.value && raf.cancel(frame.value);
    });
    return [state, setFrameState];
}
