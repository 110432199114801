var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { defineComponent } from 'vue';
import { getOptionProps, getSlot } from '../_util/props-util';
import Upload from './Upload';
import { UploadProps } from './interface';
export default defineComponent({
    name: 'AUploadDragger',
    inheritAttrs: false,
    props: UploadProps,
    render() {
        const props = getOptionProps(this);
        const { height } = props, restProps = __rest(props, ["height"]);
        const _a = this.$attrs, { style } = _a, restAttrs = __rest(_a, ["style"]);
        const draggerProps = Object.assign(Object.assign(Object.assign({}, restProps), restAttrs), { type: 'drag', style: Object.assign(Object.assign({}, style), { height }) });
        return <Upload {...draggerProps}>{getSlot(this)}</Upload>;
    },
});
