import { computed, inject, provide } from 'vue';
const KeyPathContext = Symbol('KeyPathContext');
const useInjectKeyPath = () => {
    return inject(KeyPathContext, {
        parentEventKeys: computed(() => []),
        parentKeys: computed(() => []),
        parentInfo: {},
    });
};
const useProvideKeyPath = (eventKey, key, menuInfo) => {
    const { parentEventKeys, parentKeys } = useInjectKeyPath();
    const eventKeys = computed(() => [...parentEventKeys.value, eventKey]);
    const keys = computed(() => [...parentKeys.value, key]);
    provide(KeyPathContext, { parentEventKeys: eventKeys, parentKeys: keys, parentInfo: menuInfo });
    return keys;
};
export { useProvideKeyPath, useInjectKeyPath, KeyPathContext };
export default useProvideKeyPath;
