import { createVNode, defineComponent, provide, ref } from 'vue';
import PropTypes from '../_util/vue-types';
import classNames from '../_util/classNames';
import useConfigInject from '../_util/hooks/useConfigInject';
import { SiderHookProviderKey } from './injectionKey';
export const basicProps = {
    prefixCls: PropTypes.string,
    hasSider: PropTypes.looseBool,
    tagName: PropTypes.string,
};
function generator({ suffixCls, tagName, name }) {
    return (BasicComponent) => {
        const Adapter = defineComponent({
            name,
            props: basicProps,
            setup(props, { slots }) {
                const { prefixCls } = useConfigInject(suffixCls, props);
                return () => {
                    var _a;
                    const basicComponentProps = Object.assign(Object.assign({}, props), { prefixCls: prefixCls.value, tagName });
                    return <BasicComponent {...basicComponentProps}>{(_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)}</BasicComponent>;
                };
            },
        });
        return Adapter;
    };
}
const Basic = defineComponent({
    props: basicProps,
    setup(props, { slots }) {
        return () => { var _a; return createVNode(props.tagName, { class: props.prefixCls }, (_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)); };
    },
});
const BasicLayout = defineComponent({
    props: basicProps,
    setup(props, { slots }) {
        const { direction } = useConfigInject('', props);
        const siders = ref([]);
        const siderHookProvider = {
            addSider: (id) => {
                siders.value = [...siders.value, id];
            },
            removeSider: (id) => {
                siders.value = siders.value.filter(currentId => currentId !== id);
            },
        };
        provide(SiderHookProviderKey, siderHookProvider);
        return () => {
            var _a;
            const { prefixCls, hasSider, tagName } = props;
            const divCls = classNames(prefixCls, {
                [`${prefixCls}-has-sider`]: typeof hasSider === 'boolean' ? hasSider : siders.value.length > 0,
                [`${prefixCls}-rtl`]: direction.value === 'rtl',
            });
            return createVNode(tagName, { class: divCls }, (_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots));
        };
    },
});
const Layout = generator({
    suffixCls: 'layout',
    tagName: 'section',
    name: 'ALayout',
})(BasicLayout);
const Header = generator({
    suffixCls: 'layout-header',
    tagName: 'header',
    name: 'ALayoutHeader',
})(Basic);
const Footer = generator({
    suffixCls: 'layout-footer',
    tagName: 'footer',
    name: 'ALayoutFooter',
})(Basic);
const Content = generator({
    suffixCls: 'layout-content',
    tagName: 'main',
    name: 'ALayoutContent',
})(Basic);
Layout.Header = Header;
Layout.Footer = Footer;
Layout.Content = Content;
export default Layout;
