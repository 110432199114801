var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { flattenChildren, isValidElement } from '../_util/props-util';
export function convertChildrenToData(nodes) {
    return flattenChildren(nodes)
        .map(node => {
        if (!isValidElement(node) || !node.type) {
            return null;
        }
        const _a = node.children || {}, { default: d } = _a, restSlot = __rest(_a, ["default"]);
        const children = d ? d() : [];
        const { key } = node, _b = node.props, { value } = _b, restProps = __rest(_b, ["value"]);
        const data = Object.assign({ key,
            value }, restProps);
        Object.keys(restSlot).forEach(p => {
            if (typeof restSlot[p] === 'function') {
                data[p] = <>{restSlot[p]()}</>;
            }
        });
        const childData = convertChildrenToData(children);
        if (childData.length) {
            data.children = childData;
        }
        return data;
    })
        .filter(data => data);
}
