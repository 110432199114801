import { cloneVNode } from 'vue';
const Item = ({ setRef }, { slots }) => {
    var _a;
    const children = (_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots);
    return children && children.length
        ? cloneVNode(children[0], {
            ref: setRef,
        })
        : children;
};
Item.props = {
    setRef: {
        type: Function,
        default: () => { },
    },
};
export default Item;
