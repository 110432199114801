const initDefaultProps = (types, defaultProps) => {
    const propTypes = Object.assign({}, types);
    Object.keys(defaultProps).forEach(k => {
        const prop = propTypes[k];
        if (prop) {
            prop.default = defaultProps[k];
        }
        else {
            throw new Error(`not have ${k} prop`);
        }
    });
    return propTypes;
};
export default initDefaultProps;
