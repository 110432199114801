export function isWindow(obj) {
    return obj !== null && obj !== undefined && obj === obj.window;
}
export default function getScroll(target, top) {
    var _a;
    if (typeof window === 'undefined') {
        return 0;
    }
    const method = top ? 'scrollTop' : 'scrollLeft';
    let result = 0;
    if (isWindow(target)) {
        result = target[top ? 'pageYOffset' : 'pageXOffset'];
    }
    else if (target instanceof Document) {
        result = target.documentElement[method];
    }
    else if (target) {
        result = target[method];
    }
    if (target && !isWindow(target) && typeof result !== 'number') {
        result = (_a = (target.ownerDocument || target).documentElement) === null || _a === void 0 ? void 0 : _a[method];
    }
    return result;
}
