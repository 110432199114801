import { defineComponent, computed } from 'vue';
import classNames from '../_util/classNames';
import PropTypes from '../_util/vue-types';
import useConfigInject from '../_util/hooks/useConfigInject';
const CheckableTag = defineComponent({
    name: 'ACheckableTag',
    props: {
        prefixCls: PropTypes.string,
        checked: PropTypes.looseBool,
        onChange: {
            type: Function,
        },
        onClick: {
            type: Function,
        },
    },
    emits: ['update:checked', 'change', 'click'],
    setup(props, { slots, emit }) {
        const { prefixCls } = useConfigInject('tag', props);
        const handleClick = (e) => {
            const { checked } = props;
            emit('update:checked', !checked);
            emit('change', !checked);
            emit('click', e);
        };
        const cls = computed(() => classNames(prefixCls.value, {
            [`${prefixCls.value}-checkable`]: true,
            [`${prefixCls.value}-checkable-checked`]: props.checked,
        }));
        return () => {
            var _a;
            return (<span class={cls.value} onClick={handleClick}>
          {(_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)}
        </span>);
        };
    },
});
export default CheckableTag;
