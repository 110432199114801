import classNames from '../../_util/classNames';
import { useInjectMenu } from './hooks/useMenuContext';
const InternalSubMenuList = (_props, { slots, attrs }) => {
    var _a;
    const { prefixCls, mode } = useInjectMenu();
    return (<ul {...attrs} class={classNames(prefixCls.value, `${prefixCls.value}-sub`, `${prefixCls.value}-${mode.value === 'inline' ? 'inline' : 'vertical'}`)} data-menu-list>
      {(_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)}
    </ul>);
};
InternalSubMenuList.displayName = 'SubMenuList';
export default InternalSubMenuList;
