import { onBeforeUpdate, ref } from 'vue';
export const useRef = () => {
    const refs = ref({});
    const setRef = (el, key) => {
        refs.value[key] = el;
    };
    onBeforeUpdate(() => {
        refs.value = {};
    });
    return [setRef, refs];
};
