// import PropTypes from '../../_util/vue-types';

// export default {
//   name: 'Column',
//   props: {
//     rowSpan: PropTypes.number,
//     colSpan: PropTypes.number,
//     title: PropTypes.any,
//     dataIndex: PropTypes.string,
//     width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//     ellipsis: PropTypes.looseBool,
//     fixed: PropTypes.oneOf([true, 'left', 'right']),
//     align: PropTypes.oneOf(['left', 'center', 'right']),
//     customRender: PropTypes.func,
//     className: PropTypes.string,
//     // onCellClick: PropTypes.func,
//     customCell: PropTypes.func,
//     customHeaderCell: PropTypes.func,
//   },
// };

const Column = () => null;

export default Column;
