var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { provide, inject, defineComponent } from 'vue';
import Button from '../button';
import classNames from '../_util/classNames';
import buttonTypes from '../button/buttonTypes';
import Dropdown from './dropdown';
import PropTypes from '../_util/vue-types';
import { hasProp, getComponent, getSlot } from '../_util/props-util';
import getDropdownProps from './getDropdownProps';
import { defaultConfigProvider } from '../config-provider';
import EllipsisOutlined from '@ant-design/icons-vue/EllipsisOutlined';
import { tuple } from '../_util/type';
const ButtonTypesProps = buttonTypes();
const DropdownProps = getDropdownProps();
const ButtonGroup = Button.Group;
const dropdownButtonProps = Object.assign(Object.assign({}, DropdownProps), { type: PropTypes.oneOf(tuple('primary', 'ghost', 'dashed', 'danger', 'default')).def('default'), size: PropTypes.oneOf(tuple('small', 'large', 'default')).def('default'), htmlType: ButtonTypesProps.htmlType, href: PropTypes.string, disabled: PropTypes.looseBool, prefixCls: PropTypes.string, placement: DropdownProps.placement.def('bottomRight'), icon: PropTypes.any, title: PropTypes.string, onClick: PropTypes.func, onVisibleChange: PropTypes.func, 'onUpdate:visible': PropTypes.func });
export default defineComponent({
    name: 'ADropdownButton',
    inheritAttrs: false,
    props: dropdownButtonProps,
    emits: ['click', 'visibleChange', 'update:visible'],
    setup() {
        return {
            configProvider: inject('configProvider', defaultConfigProvider),
            popupRef: null,
        };
    },
    created() {
        provide('savePopupRef', this.savePopupRef);
    },
    methods: {
        savePopupRef(ref) {
            this.popupRef = ref;
        },
        handleClick(e) {
            this.$emit('click', e);
        },
        handleVisibleChange(val) {
            this.$emit('update:visible', val);
            this.$emit('visibleChange', val);
        },
    },
    render() {
        const _a = Object.assign(Object.assign({}, this.$props), this.$attrs), { type, disabled, onClick, htmlType, class: className, prefixCls: customizePrefixCls, overlay, trigger, align, visible, onVisibleChange, placement, getPopupContainer, href, title } = _a, restProps = __rest(_a, ["type", "disabled", "onClick", "htmlType", "class", "prefixCls", "overlay", "trigger", "align", "visible", "onVisibleChange", "placement", "getPopupContainer", "href", "title"]);
        const icon = getComponent(this, 'icon') || <EllipsisOutlined />;
        const { getPopupContainer: getContextPopupContainer } = this.configProvider;
        const getPrefixCls = this.configProvider.getPrefixCls;
        const prefixCls = getPrefixCls('dropdown-button', customizePrefixCls);
        const dropdownProps = {
            align,
            disabled,
            trigger: disabled ? [] : trigger,
            placement,
            getPopupContainer: getPopupContainer || getContextPopupContainer,
            onVisibleChange: this.handleVisibleChange,
        };
        if (hasProp(this, 'visible')) {
            dropdownProps.visible = visible;
        }
        const buttonGroupProps = Object.assign(Object.assign({}, restProps), { class: classNames(prefixCls, className) });
        return (<ButtonGroup {...buttonGroupProps}>
        <Button type={type} disabled={disabled} onClick={this.handleClick} htmlType={htmlType} href={href} title={title}>
          {getSlot(this)}
        </Button>
        <Dropdown {...dropdownProps} overlay={getComponent(this, 'overlay')}>
          <Button type={type}>{icon}</Button>
        </Dropdown>
      </ButtonGroup>);
    },
});
