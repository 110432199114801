import { defineComponent, inject, nextTick } from 'vue';
import ClearableLabeledInput from './ClearableLabeledInput';
import ResizableTextArea from './ResizableTextArea';
import inputProps from './inputProps';
import { hasProp, getOptionProps } from '../_util/props-util';
import { defaultConfigProvider } from '../config-provider';
import { fixControlledValue, resolveOnChange } from './Input';
import classNames from '../_util/classNames';
import PropTypes, { withUndefined } from '../_util/vue-types';
const TextAreaProps = Object.assign(Object.assign({}, inputProps), { autosize: withUndefined(PropTypes.oneOfType([Object, Boolean])), autoSize: withUndefined(PropTypes.oneOfType([Object, Boolean])), showCount: PropTypes.looseBool, onCompositionstart: PropTypes.func, onCompositionend: PropTypes.func });
export default defineComponent({
    name: 'ATextarea',
    inheritAttrs: false,
    props: Object.assign({}, TextAreaProps),
    setup() {
        return {
            configProvider: inject('configProvider', defaultConfigProvider),
            resizableTextArea: null,
            clearableInput: null,
        };
    },
    data() {
        const value = typeof this.value === 'undefined' ? this.defaultValue : this.value;
        return {
            stateValue: typeof value === 'undefined' ? '' : value,
        };
    },
    watch: {
        value(val) {
            this.stateValue = val;
        },
    },
    mounted() {
        nextTick(() => {
            if (process.env.NODE_ENV === 'test') {
                if (this.autofocus) {
                    this.focus();
                }
            }
        });
    },
    methods: {
        setValue(value, callback) {
            if (!hasProp(this, 'value')) {
                this.stateValue = value;
            }
            else {
                this.$forceUpdate();
            }
            nextTick(() => {
                callback && callback();
            });
        },
        handleKeyDown(e) {
            if (e.keyCode === 13) {
                this.$emit('pressEnter', e);
            }
            this.$emit('keydown', e);
        },
        triggerChange(e) {
            this.$emit('update:value', e.target.value);
            this.$emit('change', e);
            this.$emit('input', e);
        },
        handleChange(e) {
            const { value, composing, isComposing } = e.target;
            if (((isComposing || composing) && this.lazy) || this.stateValue === value)
                return;
            this.setValue(e.target.value, () => {
                var _a;
                (_a = this.resizableTextArea) === null || _a === void 0 ? void 0 : _a.resizeTextarea();
            });
            resolveOnChange(this.resizableTextArea.textArea, e, this.triggerChange);
        },
        focus() {
            this.resizableTextArea.textArea.focus();
        },
        blur() {
            this.resizableTextArea.textArea.blur();
        },
        saveTextArea(resizableTextArea) {
            this.resizableTextArea = resizableTextArea;
        },
        saveClearableInput(clearableInput) {
            this.clearableInput = clearableInput;
        },
        handleReset(e) {
            this.setValue('', () => {
                this.resizableTextArea.renderTextArea();
                this.focus();
            });
            resolveOnChange(this.resizableTextArea.textArea, e, this.triggerChange);
        },
        renderTextArea(prefixCls) {
            const props = getOptionProps(this);
            const { style, class: customClass } = this.$attrs;
            const resizeProps = Object.assign(Object.assign(Object.assign({}, props), this.$attrs), { style: !props.showCount && style, class: !props.showCount && customClass, showCount: null, prefixCls, onInput: this.handleChange, onChange: this.handleChange, onKeydown: this.handleKeyDown });
            return <ResizableTextArea {...resizeProps} ref={this.saveTextArea}/>;
        },
    },
    render() {
        const { stateValue, prefixCls: customizePrefixCls, maxlength, showCount } = this;
        const { style, class: customClass } = this.$attrs;
        const getPrefixCls = this.configProvider.getPrefixCls;
        const prefixCls = getPrefixCls('input', customizePrefixCls);
        let value = fixControlledValue(stateValue);
        // Max length value
        const hasMaxlength = Number(maxlength) > 0;
        value = hasMaxlength ? value.slice(0, maxlength) : value;
        const props = Object.assign(Object.assign(Object.assign({}, getOptionProps(this)), this.$attrs), { prefixCls, inputType: 'text', element: this.renderTextArea(prefixCls), handleReset: this.handleReset });
        let textareaNode = (<ClearableLabeledInput {...props} value={value} ref={this.saveClearableInput}/>);
        if (showCount) {
            const valueLength = [...value].length;
            const dataCount = `${valueLength}${hasMaxlength ? ` / ${maxlength}` : ''}`;
            textareaNode = (<div class={classNames(`${prefixCls}-textarea`, `${prefixCls}-textarea-show-count`, customClass)} style={style} data-count={dataCount}>
          {textareaNode}
        </div>);
        }
        return textareaNode;
    },
});
