import { defineComponent } from 'vue';
import classNames from '../_util/classNames';
import PropTypes, { withUndefined } from '../_util/vue-types';
import { initDefaultProps } from '../_util/props-util';
import { avatarProps } from './Avatar';
import Title, { skeletonTitleProps } from './Title';
import Paragraph, { skeletonParagraphProps } from './Paragraph';
import Omit from 'omit.js';
import useConfigInject from '../_util/hooks/useConfigInject';
import Element from './Element';
export const skeletonProps = {
    active: PropTypes.looseBool,
    loading: PropTypes.looseBool,
    prefixCls: PropTypes.string,
    avatar: withUndefined(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape(Omit(avatarProps, ['active'])).loose,
        PropTypes.looseBool,
    ])),
    title: withUndefined(PropTypes.oneOfType([
        PropTypes.looseBool,
        PropTypes.string,
        PropTypes.shape(skeletonTitleProps).loose,
    ])),
    paragraph: withUndefined(PropTypes.oneOfType([
        PropTypes.looseBool,
        PropTypes.string,
        PropTypes.shape(skeletonParagraphProps).loose,
    ])),
};
function getComponentProps(prop) {
    if (prop && typeof prop === 'object') {
        return prop;
    }
    return {};
}
function getAvatarBasicProps(hasTitle, hasParagraph) {
    if (hasTitle && !hasParagraph) {
        // Square avatar
        return { size: 'large', shape: 'square' };
    }
    return { size: 'large', shape: 'circle' };
}
function getTitleBasicProps(hasAvatar, hasParagraph) {
    if (!hasAvatar && hasParagraph) {
        return { width: '38%' };
    }
    if (hasAvatar && hasParagraph) {
        return { width: '50%' };
    }
    return {};
}
function getParagraphBasicProps(hasAvatar, hasTitle) {
    const basicProps = {};
    // Width
    if (!hasAvatar || !hasTitle) {
        basicProps.width = '61%';
    }
    // Rows
    if (!hasAvatar && hasTitle) {
        basicProps.rows = 3;
    }
    else {
        basicProps.rows = 2;
    }
    return basicProps;
}
const Skeleton = defineComponent({
    name: 'ASkeleton',
    props: initDefaultProps(skeletonProps, {
        avatar: false,
        title: true,
        paragraph: true,
    }),
    setup(props, { slots }) {
        const { prefixCls, direction } = useConfigInject('skeleton', props);
        return () => {
            var _a;
            const { loading, avatar, title, paragraph, active, round } = props;
            const pre = prefixCls.value;
            if (loading || props.loading === undefined) {
                const hasAvatar = !!avatar || avatar === '';
                const hasTitle = !!title || title === '';
                const hasParagraph = !!paragraph || paragraph === '';
                // Avatar
                let avatarNode;
                if (hasAvatar) {
                    const avatarProps = Object.assign(Object.assign({ prefixCls: `${pre}-avatar` }, getAvatarBasicProps(hasTitle, hasParagraph)), getComponentProps(avatar));
                    avatarNode = (<div class={`${pre}-header`}>
              <Element {...avatarProps}/>
            </div>);
                }
                let contentNode;
                if (hasTitle || hasParagraph) {
                    // Title
                    let $title;
                    if (hasTitle) {
                        const titleProps = Object.assign(Object.assign({ prefixCls: `${pre}-title` }, getTitleBasicProps(hasAvatar, hasParagraph)), getComponentProps(title));
                        $title = <Title {...titleProps}/>;
                    }
                    // Paragraph
                    let paragraphNode;
                    if (hasParagraph) {
                        const paragraphProps = Object.assign(Object.assign({ prefixCls: `${pre}-paragraph` }, getParagraphBasicProps(hasAvatar, hasTitle)), getComponentProps(paragraph));
                        paragraphNode = <Paragraph {...paragraphProps}/>;
                    }
                    contentNode = (<div class={`${pre}-content`}>
              {$title}
              {paragraphNode}
            </div>);
                }
                const cls = classNames(pre, {
                    [`${pre}-with-avatar`]: hasAvatar,
                    [`${pre}-active`]: active,
                    [`${pre}-rtl`]: direction.value === 'rtl',
                    [`${pre}-round`]: round,
                });
                return (<div class={cls}>
            {avatarNode}
            {contentNode}
          </div>);
            }
            return (_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots);
        };
    },
});
export default Skeleton;
