export default (element) => {
    if (!element) {
        return false;
    }
    if (element.offsetParent) {
        return true;
    }
    if (element.getBBox) {
        const box = element.getBBox();
        if (box.width || box.height) {
            return true;
        }
    }
    if (element.getBoundingClientRect) {
        const box = element.getBoundingClientRect();
        if (box.width || box.height) {
            return true;
        }
    }
    return false;
};
