// https://stackoverflow.com/questions/46176165/ways-to-get-string-literal-type-of-array-values-without-enum-overhead
export const tuple = (...args) => args;
export const tupleNum = (...args) => args;
export const withInstall = (comp) => {
    const c = comp;
    c.install = function (app) {
        app.component(c.displayName || c.name, comp);
    };
    return comp;
};
