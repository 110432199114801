var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { defineComponent, inject } from 'vue';
import PropTypes from '../_util/vue-types';
import BaseMixin from '../_util/BaseMixin';
import { getOptionProps } from '../_util/props-util';
import VcSlider from '../vc-slider/src/Slider';
import VcRange from '../vc-slider/src/Range';
import VcHandle from '../vc-slider/src/Handle';
import Tooltip from '../tooltip';
import { defaultConfigProvider } from '../config-provider';
import abstractTooltipProps from '../tooltip/abstractTooltipProps';
import { withInstall } from '../_util/type';
const tooltipProps = abstractTooltipProps();
export const SliderProps = () => ({
    prefixCls: PropTypes.string,
    tooltipPrefixCls: PropTypes.string,
    range: PropTypes.looseBool,
    reverse: PropTypes.looseBool,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.any,
    marks: PropTypes.object,
    dots: PropTypes.looseBool,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    included: PropTypes.looseBool,
    disabled: PropTypes.looseBool,
    vertical: PropTypes.looseBool,
    tipFormatter: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    tooltipVisible: PropTypes.looseBool,
    tooltipPlacement: tooltipProps.placement,
    getTooltipPopupContainer: PropTypes.func,
    onChange: PropTypes.func,
    onAfterChange: PropTypes.func,
});
const defaultTipFormatter = (value) => value.toString();
const Slider = defineComponent({
    name: 'ASlider',
    mixins: [BaseMixin],
    inheritAttrs: false,
    props: Object.assign({}, SliderProps()),
    emits: ['update:value', 'change'],
    setup() {
        return {
            vcSlider: null,
            configProvider: inject('configProvider', defaultConfigProvider),
        };
    },
    data() {
        return {
            visibles: {},
        };
    },
    methods: {
        toggleTooltipVisible(index, visible) {
            this.setState(({ visibles }) => ({
                visibles: Object.assign(Object.assign({}, visibles), { [index]: visible }),
            }));
        },
        handleWithTooltip(tooltipPrefixCls, prefixCls, _a) {
            var { value, dragging, index } = _a, restProps = __rest(_a, ["value", "dragging", "index"]);
            const { tipFormatter = defaultTipFormatter, tooltipVisible, tooltipPlacement, getTooltipPopupContainer, } = this.$props;
            const { visibles } = this;
            const isTipFormatter = tipFormatter ? visibles[index] || dragging : false;
            const visible = tooltipVisible || (tooltipVisible === undefined && isTipFormatter);
            const tooltipProps = {
                prefixCls: tooltipPrefixCls,
                title: tipFormatter ? tipFormatter(value) : '',
                visible,
                placement: tooltipPlacement || 'top',
                transitionName: 'zoom-down',
                overlayClassName: `${prefixCls}-tooltip`,
                getPopupContainer: getTooltipPopupContainer || (() => document.body),
                key: index,
            };
            const handleProps = Object.assign(Object.assign({ value }, restProps), { onMouseenter: () => this.toggleTooltipVisible(index, true), onMouseleave: () => this.toggleTooltipVisible(index, false) });
            return (<Tooltip {...tooltipProps}>
          <VcHandle {...handleProps}/>
        </Tooltip>);
        },
        saveSlider(node) {
            this.vcSlider = node;
        },
        focus() {
            this.vcSlider.focus();
        },
        blur() {
            this.vcSlider.blur();
        },
        handleChange(val) {
            this.$emit('update:value', val);
            this.$emit('change', val);
        },
    },
    render() {
        const _a = Object.assign(Object.assign({}, getOptionProps(this)), this.$attrs), { range, prefixCls: customizePrefixCls, tooltipPrefixCls: customizeTooltipPrefixCls } = _a, restProps = __rest(_a, ["range", "prefixCls", "tooltipPrefixCls"]);
        const { getPrefixCls } = this.configProvider;
        const prefixCls = getPrefixCls('slider', customizePrefixCls);
        const tooltipPrefixCls = getPrefixCls('tooltip', customizeTooltipPrefixCls);
        if (range) {
            const vcRangeProps = Object.assign(Object.assign({}, restProps), { prefixCls,
                tooltipPrefixCls, handle: (info) => this.handleWithTooltip(tooltipPrefixCls, prefixCls, info), ref: this.saveSlider, onChange: this.handleChange });
            return <VcRange {...vcRangeProps}/>;
        }
        const vcSliderProps = Object.assign(Object.assign({}, restProps), { prefixCls,
            tooltipPrefixCls, handle: (info) => this.handleWithTooltip(tooltipPrefixCls, prefixCls, info), ref: this.saveSlider, onChange: this.handleChange });
        return <VcSlider {...vcSliderProps}/>;
    },
});
export default withInstall(Slider);
