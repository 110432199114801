var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { computed, defineComponent } from 'vue';
import Checkbox from '../checkbox';
import Radio from '../radio';
import { SelectionBoxProps } from './interface';
import BaseMixin from '../_util/BaseMixin';
import { getOptionProps } from '../_util/props-util';
export default defineComponent({
    name: 'SelectionBox',
    mixins: [BaseMixin],
    inheritAttrs: false,
    props: SelectionBoxProps,
    setup(props) {
        return {
            checked: computed(() => {
                const { store, defaultSelection, rowIndex } = props;
                let checked = false;
                if (store.selectionDirty) {
                    checked = store.selectedRowKeys.indexOf(rowIndex) >= 0;
                }
                else {
                    checked =
                        store.selectedRowKeys.indexOf(rowIndex) >= 0 || defaultSelection.indexOf(rowIndex) >= 0;
                }
                return checked;
            }),
        };
    },
    render() {
        const _a = Object.assign(Object.assign({}, getOptionProps(this)), this.$attrs), { type, rowIndex } = _a, rest = __rest(_a, ["type", "rowIndex"]);
        const { checked } = this;
        const checkboxProps = Object.assign({ checked }, rest);
        if (type === 'radio') {
            checkboxProps.value = rowIndex;
            return <Radio {...checkboxProps}/>;
        }
        return <Checkbox {...checkboxProps}/>;
    },
});
