import { onBeforeUnmount, ref } from 'vue';
import wrapperRaf from '../raf';
/**
 * Execute code before next frame but async
 */
export function useLayoutState(defaultState) {
    const stateRef = ref(defaultState);
    let tempState = stateRef.value;
    let updateBatchRef = [];
    const rafRef = ref();
    function setFrameState(updater) {
        wrapperRaf.cancel(rafRef.value);
        updateBatchRef.push(updater);
        rafRef.value = wrapperRaf(() => {
            const prevBatch = updateBatchRef;
            // const prevState = stateRef.value;
            updateBatchRef = [];
            prevBatch.forEach(batchUpdater => {
                tempState = batchUpdater(tempState);
            });
            // if (tempState !== stateRef.value) {
            stateRef.value = tempState;
            // }
        });
    }
    onBeforeUnmount(() => {
        wrapperRaf.cancel(rafRef.value);
    });
    return [stateRef, setFrameState];
}
