import { reactive } from 'vue';
export default function useHeights(getKey, onItemAdd, onItemRemove) {
    const instance = new Map();
    const heights = reactive({});
    let heightUpdateId = 0;
    function collectHeight() {
        heightUpdateId += 1;
        const currentId = heightUpdateId;
        Promise.resolve().then(() => {
            // Only collect when it's latest call
            if (currentId !== heightUpdateId)
                return;
            // let changed = false;
            instance.forEach((element, key) => {
                if (element && element.offsetParent) {
                    const { offsetHeight } = element;
                    if (heights[key] !== offsetHeight) {
                        //changed = true;
                        heights[key] = element.offsetHeight;
                    }
                }
            });
        });
    }
    function setInstance(item, ins) {
        const key = getKey(item);
        const origin = instance.get(key);
        if (ins) {
            instance.set(key, ins);
            collectHeight();
        }
        else {
            instance.delete(key);
        }
        // Instance changed
        if (!origin !== !ins) {
            if (ins) {
                onItemAdd === null || onItemAdd === void 0 ? void 0 : onItemAdd(item);
            }
            else {
                onItemRemove === null || onItemRemove === void 0 ? void 0 : onItemRemove(item);
            }
        }
    }
    return [setInstance, collectHeight, heights];
}
