var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { defineComponent, inject } from 'vue';
import Radio, { radioProps } from './Radio';
import { getOptionProps, getSlot } from '../_util/props-util';
import { defaultConfigProvider } from '../config-provider';
export default defineComponent({
    name: 'ARadioButton',
    props: Object.assign({}, radioProps),
    setup() {
        return {
            configProvider: inject('configProvider', defaultConfigProvider),
            radioGroupContext: inject('radioGroupContext', {}),
        };
    },
    render() {
        const props = getOptionProps(this);
        const { prefixCls: customizePrefixCls } = props, otherProps = __rest(props, ["prefixCls"]);
        const { getPrefixCls } = this.configProvider;
        const prefixCls = getPrefixCls('radio-button', customizePrefixCls);
        const rProps = Object.assign({ prefixCls }, otherProps);
        if (this.radioGroupContext) {
            rProps.onChange = this.radioGroupContext.onRadioChange;
            rProps.checked = props.value === this.radioGroupContext.stateValue;
            rProps.disabled = props.disabled || this.radioGroupContext.disabled;
        }
        return <Radio {...rProps}>{getSlot(this)}</Radio>;
    },
});
